@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
  overflow-x: hidden;
}