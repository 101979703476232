/* HOME BANER */

.BanerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.Baner {
    background-image: url("../Images/Background1.jpg");

    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: brightness(30%);
    width: 100%;
    height: 100%;

}

.BanerContent {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    color: white;
    font-size: 3rem;
    cursor: default
}


.BanerContent .LinkButton {
    font-size: 3.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    padding: 1rem;
    position: relative;
    overflow: hidden;
    color: white;
}

.BanerContent .LinkButton:before {
    content: "";
    position: absolute;
    width: 0;
    background: rgb(255, 255, 255);
    left: 45%;
    height: 3px;
    bottom: 0;
    transition: all .25s;
    opacity: 0.7;
}

.BanerContent .LinkButton:hover:before {
    width: 100%;
    left: 0;
}

.BanerContent .Link {
    text-decoration: none;
    color: white;
}



/* SUB NAV BAR */

.NavBarSubContainer {
    position: sticky;
    width: 100%;
    height: 110px;
    top: 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1;

    transform: translateY(-65px);
}

.NavBarSubBox {
    padding: 1rem;
    width: 60%;
    height: 100%;
    background-color: white;
    /* border-radius: 15px; */
    -webkit-box-shadow: 0px 14px 34px -3px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 14px 34px -3px rgba(0, 0, 0, 1);
    box-shadow: 0px 14px 34px -3px rgba(0, 0, 0, 1);

    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    border-radius: 0 0 10px 10px;
}

.NavBarSubBoxCont {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    height: 50%;
}


.NavBarSubBoxBtn {
    width: 95%;
    height: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 2rem;


}


.NavBarSubBoxBtn button {
    font-size: 2rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    padding: 0.5rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.NavBarSubBoxBtn button:before {
    content: "";
    position: absolute;
    width: 0;
    background: black;
    left: 45%;
    height: 2px;
    bottom: 0;
    transition: all .25s;
    opacity: 0.7;
}

.NavBarSubBoxBtn button:hover:before {
    width: 100%;
    left: 0;
}

.NavBarSubLogo {
    display: flex;
    position: absolute;
    left: 10px;

    padding: 0.5rem;
    width: 125px;
    height: 100%;
    background-color: white;
    -webkit-box-shadow: 0px 14px 34px -3px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 14px 34px -3px rgba(0, 0, 0, 1);
    box-shadow: 0px 14px 34px -3px rgba(0, 0, 0, 1);
    border-radius: 0 0 10px 10px;

    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    font-size: 4rem;
    font-weight: 800;
    font-family: Arial, Helvetica, sans-serif;
    color: blue;

    cursor: pointer;
}


/* GALLERY */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');



.Gallery {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100vw;
}

section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MainImageContainer {
    width: 75%;
    height: 420px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    margin: 5rem 0 5rem 0;
}

.MainImageContent {
    border-radius: 10px;
    border: 0.2rem solid black;
    height: 100%;
    width: fit-content;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
}

.MainImageContent.hide {
    opacity: 0;
}


.ButtonLeft,
.ButtonRight {
    position: relative;
    left: 0px;
    font-family: 'Poppins', sans-serif;
    font-size: 10rem;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ButtonLeft:hover {
    left: -15px;
    transition: all 0.2s ease-in-out;
}

.ButtonRight:hover {
    left: 15px;
    transition: all 0.2s ease-in-out;
}


#AnchorKuchnie,
#AnchorSzafy,
#AnchorŁazienki,
#AnchorPoddasza {
    position: relative;
    top: -10rem;
}


.TitleGalleryContainer,
.TitleGalleryContainerRev {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 90%;
    height: 350px;
    margin: 5rem 0 2rem 0;
}

.ContentBoxGalleryDR {
    -webkit-box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 1);
    box-shadow: 8px 8px 24px 0px rgba(0, 0, 0, 1);
}

.ContentBoxGalleryDL {
    -webkit-box-shadow: -8px 8px 24px 0px rgba(0, 0, 0, 1);
    -moz-box-shadow: -8px 8px 24px 0px rgba(0, 0, 0, 1);
    box-shadow: -8px 8px 24px 0px rgba(0, 0, 0, 1);
}

.ContentBoxGalleryDL,
.ContentBoxGalleryDR {
    width: 45%;
    height: fit-content;
    font-size: 2rem;
    border-radius: 1rem;

    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    text-align: center;
}

.TitleGallery {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 100%;

    font-size: 7rem;
    text-transform: uppercase;


}


ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 95%;
}

li {
    height: 43vh;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0.6rem;
    list-style: none;
}

li:last-child {
    -webkit-box-flex: 10;
    -ms-flex-positive: 10;
    flex-grow: 10;
}

.GalleryImages {
    max-height: 100%;
    min-width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    vertical-align: bottom;
    border-radius: 10px;
    border: 0.2rem solid black;
    -webkit-filter: brightness(95%);
    filter: brightness(95%);
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.GalleryImages:hover {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-filter: brightness(110%);
    filter: brightness(110%);
}






.NavBarSubBoxMobile {
    display: none;
    padding: 1rem;
    background-color: white;
    -webkit-box-shadow: 0px 14px 34px -3px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 14px 34px -3px rgba(0, 0, 0, 1);
    box-shadow: 0px 14px 34px -3px rgba(0, 0, 0, 1);
    border-radius: 0 0 10px 10px;
    width: 200px;
    height: 130px;

    justify-content: center;
    align-items: flex-end;
}

.GridContent {
    display: grid;
    grid-template-rows: 1fr 2fr 2fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.1rem;
    width: fit-content;
    height: fit-content;
}

.NavBarSubBoxMobile button {
    font-size: 1.2rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    padding: 0.5rem;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.NavBarSubBoxMobile button:before {
    content: "";
    position: absolute;
    width: 0;
    background: black;
    left: 45%;
    height: 2px;
    bottom: 0;
    transition: all .25s;
    opacity: 0.7;
}

.NavBarSubBoxMobile button:hover:before {
    width: 100%;
    left: 0;
}


.NavBarSubBoxContMobile {
    font-size: 1rem;
}

.item-1-1 {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.item-2-1 {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-2-2 {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-3-1 {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item-3-2 {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
    display: flex;
    justify-content: center;
    align-items: center;
}



@media only screen and (max-width: 1100px) {

    .MainImageContainer {
        width: 90%;
    }


}

@media only screen and (max-width: 800px) {

    .NavBarSubBox {
        width: 50%;
    }


    .NavBarSubBoxCont,
    .NavBarSubBoxBtn,
    .NavBarSubBoxBtn button {
        font-size: 1.5rem;
    }


    .MainImageContainer {
        display: none;
    }

    .TitleGallery {
        font-size: 5rem;
    }

    .ContentBoxGalleryDL,
    .ContentBoxGalleryDR {
        font-size: 1.5rem;
        height: 90%;
    }

}


@media only screen and (max-width: 650px) {

    .BanerContent {
        font-size: 2rem;
    }

    .BanerContent .LinkButton {
        font-size: 2.5rem;
    }

    .NavBarSubBox {

        width: 60%;
    }

    .NavBarSubLogo {
        width: 100px;
        font-size: 2.5rem;
        height: 80px;
    }

    .ContentBoxGalleryDL,
    .ContentBoxGalleryDR {
        height: 80%;
    }
}

@media only screen and (max-width: 600px) {

    .NavBarSubBox {
        display: none;
    }

    .NavBarSubBoxMobile {
        display: flex;
    }

    .TitleGalleryContainer {
        flex-direction: column;
    }

    .TitleGalleryContainerRev {
        flex-direction: column-reverse;
    }

    .ContentBoxGalleryDL,
    .ContentBoxGalleryDR {
        width: 80%;
        height: 75%;
    }


    .TitleGallery {
        height: 25%;

    }

}

@media only screen and (max-width: 500px) {



    .GalleryImages {
        width: 70%;
    }

}


@media only screen and (max-width: 450px) {

    .NavBarSubLogo {
        left: 0px;
        font-size: 1rem;
        width: 50px;
        height: 35%;

    }

}