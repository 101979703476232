:root {
    --navBarBackground: #FFF4E0;
    --navBarFont: ;
    --navBarAccents: rgb(255, 255, 255);
}

nav {
    position: absolute;
    width: 100vw;
    height: 100px;
}

.MainBarContainer {
    height: 100%;
    width: 100%;
    padding: 0 1rem 0 1rem;
    /* border-bottom: solid 1px rgb(128, 128, 128, 0.5); */
    display: flex;
    justify-content: flex-start;
    align-items: center;

    z-index: 1;
}

.LogoContainer {
    width: 300px;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    line-height: 4rem;
    cursor: pointer;
}

.MainLogo {
    font-size: 6.5rem;
    font-weight: 800;
    font-family: Arial, Helvetica, sans-serif;
    color: blue;
}

.SubLogo {
    font-size: 2rem;
    font-weight: 800;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 3px;
    color: red;
}

.MainBarButtons {
    height: 100%;
    width: 800px;
    font-size: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.MainBarButtons button {
    font-size: 2rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    padding: 1rem;
    position: relative;
    overflow: hidden;
    color: white;
}

.MainBarButtons button:before {
    content: "";
    position: absolute;
    width: 0;
    background: rgb(255, 255, 255);
    left: 45%;
    height: 2px;
    bottom: 0;
    transition: all .25s;
    opacity: 0.7;
}

.MainBarButtons button:hover:before {
    width: 100%;
    left: 0;
}

.Link {
    text-decoration: none;
    color: white;
}






.BurgerBtn {
    display: none;
    position: fixed;

    top: 1rem;
    right: 3rem;

    width: 4rem;
    height: 3rem;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    z-index: 3;
    /* background-color: aqua; */
}

.BurgerBtn span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: rgb(255, 0, 0);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.BurgerBtn span:nth-child(1) {
    top: 0%;
}

.BurgerBtn span:nth-child(2) {
    top: 50%;
}

.BurgerBtn span:nth-child(3) {
    top: 100%;
}

.BurgerBtn.on span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
}

.BurgerBtn.on span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.BurgerBtn.on span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}




.MobileMenu {
    display: none;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 50%;
    z-index: 1;
    transform: translate(100%);
    transition: all 0.3s ease-in-out;
    color: white;
    background-color: rgba(0, 0, 0, 0.95);
    z-index: 2;
}

.MobileMenu.on {
    transition: all 0.3s ease-in-out;
    transform: translate(0%);
}


.MobileMenuContent {
    width: 80%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.MobileMenuContent button {
    font-size: 2.5rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    padding: 1rem;
    position: relative;
    overflow: hidden;
    color: white;
}


@media only screen and (max-width: 800px) {

    .MainBarButtons {
        display: none;
    }

    .MobileMenu,
    .BurgerBtn {
        display: flex;
    }
}

@media only screen and (max-width: 650px) {

    .MainBarContainer {
        height: 70px;
    }

    .LogoContainer {
        width: 200px;
    }

    .BurgerBtn {
        width: 3.5rem;
    }

    .MobileMenu {
        width: 100%;
    }

    .LogoContainer {
        line-height: 3rem;
    }

    .MainLogo {
        font-size: 5rem;
    }

    .SubLogo {
        font-size: 1.5rem;
    }

}

@media only screen and (max-width: 350px) {
    .BurgerBtn {
        right: 2rem;
        width: 2.5rem;
        height: 2.5rem;
    }
}