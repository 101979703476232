.ProductionBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
}

.ProductionBackground .Background {
    background-image: url("../Images/Background1.jpg");

    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: brightness(30%);
    width: 100%;
    height: 100%;
}

.ProductionContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ProductionContentBox {
    width: 80%;
    height: 50%;
}

.ProductionContent {
    width: 100%;
    height: 100%;
    font-size: 2rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    cursor: default;
}

.ProductionContent .LinkButton {
    font-size: 2rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    padding: 0.3rem;
    position: relative;
    overflow: hidden;
    color: white;
}

.ProductionContent .LinkButton:before {
    content: "";
    position: absolute;
    width: 0;
    background: rgb(255, 255, 255);
    left: 45%;
    height: 3px;
    bottom: 2px;
    transition: all .25s;
    opacity: 1;
}

.ProductionContent .LinkButton:hover:before {
    width: 100%;
    left: 0;
}

.ProductionContent .Link {
    text-decoration: none;
    color: white;
}

@media only screen and (max-width: 800px) {

    .ProductionContent,
    .ProductionContent .LinkButton {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 550px) {

    .ProductionContent,
    .ProductionContent .LinkButton {
        font-size: 1.3rem;
    }
}

@media only screen and (max-width: 400px) {

    .ProductionContent,
    .ProductionContent .LinkButton {
        font-size: 1rem;
    }
}