/* BACKGROUND */

.ContactBackground {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
}

.ContactBackground .Background {
    background-image: url("../Images/Background1.jpg");

    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: brightness(30%);
    width: 100%;
    height: 100%;
}


.ContactContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.ContactLeft {
    position: relative;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 60%;
    z-index: 0;
}

.ContactRight {
    position: relative;
    top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    width: 45%;
    height: 50%;
}

.RightTitle {
    width: 100%;
    font-size: 2.5rem;
    color: white;
}

.RightList {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 2rem;
    color: white;
}

.RightList p {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    cursor: pointer;
}

.RightList img {
    margin-right: 2rem;
}


/* MAP */

.Map {
    width: 100%;
    height: 100%;

    border-radius: 10px;
}

.MapDescription {
    font-size: 1.5rem;
}






@media only screen and (max-width: 800px) {

    .ContactBackground {
        width: 100%;
        height: 120vh;
    }

    .RightList {
        font-size: 1.5rem;
    }

    .RightList p {
        margin-top: 0.1rem;
    }

    .RightList img {
        margin-right: 1rem;
        width: fit-content;
        height: 70%;
    }

    .ContactContainer {
        position: relative;
        top: 10rem;
        flex-direction: column-reverse;
    }

    .ContactLeft {
        height: 45%;
        width: 60%
    }

    .ContactRight {
        height: 40%;
        width: 80%
    }
}



@media only screen and (max-width: 350px) {

    .RightTitle {
        font-size: 2rem;
    }

    .RightList {
        font-size: 1.2rem;
    }

    .RightList p {
        margin-top: 0.1rem;
        overflow-wrap: break-word;
    }

    .RightList img {
        margin-right: 0.5rem;
        width: fit-content;
        height: 55%;
    }

    .ContactLeft {
        width: 95%
    }
}